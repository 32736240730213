import React, { Fragment, Component } from 'react'
import Header from "../Common/Header/Header"
import Footer from "../Common/Footer/Footer"
import HeaderBottom from './HeaderBottom'


const HOC = (Wcomponent) => {
    console.log(Wcomponent, " hoc element coming on the page of higher order component page")
    return class extends Component {
        render() {
            return (
                <Fragment>
                    <div className='app-container-hoc main-margin'>
                        <Header />
                        <HeaderBottom />
                        <div>
                            <Wcomponent />
                        </div>
                        <Footer />
                    </div>
                </Fragment>
            )
        }
    }
}

export default HOC