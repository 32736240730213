import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import image from "../../images/blog1.jpg"
import { Box } from '@material-ui/core';
import HOC from '../../Common/HOC';
import { Navigate, useNavigate } from 'react-router-dom';
import { pujaDetail_api } from '../api/puja';
import s from "./puja.module.css"

const Puja = () => {
    const navigate = useNavigate();

    const [pujaDetail, setPujaDetail] = useState([])
    // console.log(pujaDetail, "pujaDetail pujaDetail pujaDetail pujaDetail pujaDetail pujaDetail pujaDetail pujaDetail pujaDetail")

    const handleDetailPage = (data) => {
        navigate(`/${data.slug}`, {
            state: {
                data: data,
            },
        });
    };


    useEffect(() => {
        pujaDeatilFunc();
    }, [])

    const pujaDeatilFunc = async () => {
        let temp = {
        }
        try {
            let res = await pujaDetail_api(temp)
            if (res.data.status) {
                setPujaDetail(res.data.data)
            }
        } catch (error) {
            console.log("status false....")
        }
    }

    function handleContentFunc(data, limit) {
        // console.log(data, limit, "data come in the handle conent function of the about puja")
        let content = `${data?.split(" ")?.slice(0, limit)?.join(" ")}...`
        return content;
    }

    return (
        <div className='container'>
            <div style={{ display: "flex", width: "100%", justifyContent: "center", margin: "1rem" }}>
                <h5>Puja Section </h5>
            </div>
            <div className={s['puja_card']}>
                {pujaDetail?.map((data, index) => (data.result.map((item, index) => (
                    < Card sx={{ maxWidth: 345 }} style={{ cursor: "pointer", objectFit: "fill" }} className={s["image_set"]} onClick={() => handleDetailPage(item)}>
                        <CardMedia
                            component="img"
                            alt="puja image"
                            height="140"
                            image={item?.pujaImage}
                        />
                        <CardContent style={{ height: "10rem" }}>
                            <Typography gutterBottom variant="h6" component="div">
                                {item.title}({String(item?.pujaDatetime).substring(0, 10)})
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {/* {item.aboutPuja} */}
                                {handleContentFunc(item.aboutPuja, 10)}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <div style={{ display: 'flex', justifyContent: 'end', width: '100%', textTransform: 'lowercase' }}>
                                <Button size="small" onClick={() => handleDetailPage(item)}>Book Puja</Button>
                            </div>
                        </CardActions>
                    </Card>
                ))
                ))}
            </div>
        </div >

    )
}


export default HOC(Puja);



