import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import React from 'react'
import {
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import ChangingProgressProvider from '../Components/api/ChangingProgressProvider';

const CircularProgress = ({ values, fullScore }) => {
    const value = parseInt((values / fullScore) * 100);
    console.log(value, "value in the data 1111111111111111111111111111111111!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
    return (
        <div style={{ width: "70px", height: "70px" }}>
            <CircularProgressbar value={values} maxValue={fullScore} text={value === 0 ? "0%" : `${value}%`} />
        </div>
    )
}

export default CircularProgress;

export const GunnMatching = ({ gun }) => {
    console.log(gun, "gun coming on the pge where  we try to mske our gun matchig meter")
    const gunMeter = parseInt(gun / 36 * 100)
    return (
        <div label="Dashboard/speedometer" style={{ width: "200px", marginTop: "1rem" }}>
            <ChangingProgressProvider values={[gunMeter]}>
                {value => (
                    <CircularProgressbar
                        value={value}
                        text={`${value}%`}
                        circleRatio={0.75}
                        styles={buildStyles({
                            rotation: 1 / 2 + 1 / 8,
                            strokeLinecap: "butt",
                            trailColor: "#eee"
                        })}
                    />
                )}
            </ChangingProgressProvider>
        </div>
    )
}
// export default GunnMatching;
