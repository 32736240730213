import React, { useEffect, useState } from 'react'
import img1 from "../../images/banner2.png"
import s from "./../Puja/puja.module.css"
import HOC from '../../Common/HOC'
import Carousel from 'react-multi-carousel'
import { pujaDetailById_api, pujaDetail_api } from '../api/puja'
import { useLocation, useParams } from 'react-router-dom'
import PujaFaqSection from './../Puja/PujaFaqSection'
import PujaSubscription from './../Puja/PujaSubscription'
import { Helmet } from "react-helmet";
const NavaGrahDoshNivaranPuja = () => {


    const location = useLocation()
    const id = location?.state?.data;
    const [pujaDetailById, setPujaDetailById] = useState({})
    const [isLoading, SetisLoading] = useState(false)



    const responsive = {
        desktop: {
            breakpoint: {
                max: 3000,
                min: 1024
            },
            items: 1,
        },
        mobile: {
            breakpoint: {
                max: 464,
                min: 0
            },
            items: 1,
        },
        tablet: {
            breakpoint: {
                max: 1024,
                min: 464
            },
            items: 2,
        }

    }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        pujaDeatilFunc("Nava-Grah-Dosh-Nivaran-Puja");
    }, []);

    const pujaDeatilFunc = async (data) => {
        SetisLoading(true)
        let temp = {
            instaId: data
        }
        try {
            let res = await pujaDetailById_api(temp)
            if (res.data.status) {
                setPujaDetailById(res.data.data)
                SetisLoading(false)
            }
        } catch (error) {
            SetisLoading(false)
            console.log("status false....")

        }
    }
    return (
        <div className='puja_detail_page'>
            <Helmet>
                <title>Online Navagraha Shanti Puja | Pajdit Ji for Navagraha Dosha Nivaran Puja</title>
                <meta name="description" content="Book Online Navagraha Shanti Puja with Astrosetu. Expert Pandit ji performs rituals for Navagraha Dosha Nivaran. Bring harmony and balance into your life. Schedule your puja today for peace and prosperity!" />
                <meta name="keyword" content="" />
                <link rel="canonical" href="https://astrosetu.in/puja/Nava-Grah-Dosh-Nivaran-Puja" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="Website" />
                <meta property="og:title" content="Online Navagraha Shanti Puja | Pajdit Ji for Navagraha Dosha Nivaran Puja" />
                <meta property="og:description" content="Book Online Navagraha Shanti Puja with Astrosetu. Expert Pandit ji performs rituals for Navagraha Dosha Nivaran. Bring harmony and balance into your life. Schedule your puja today for peace and prosperity!" />
                <meta property="og:site_name" content="Astrosetu" />
                <meta property="og:url" content="https://astrosetu.in" />
                <meta property="og:image" content="https://admin.astrosetu.in/images/puja_img_1721980727369.jpg" />
                <meta property="og:image:width" content="400" /><meta property="og:image:height" content="400" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Online Navagraha Shanti Puja | Pajdit Ji for Navagraha Dosha Nivaran Puja" />
                <meta name="twitter:description" content="Book Online Navagraha Shanti Puja with Astrosetu. Expert Pandit ji performs rituals for Navagraha Dosha Nivaran. Bring harmony and balance into your life. Schedule your puja today for peace and prosperity!" />
                <meta name="twitter:image" content="https://admin.astrosetu.in/images/puja_img_1721980727369.jpg" />

            </Helmet>

            <div className="container">
                {/* <div className="puja_heading">
                    <h3>Pooja</h3>
                </div> */}
                <div className='puja_image' style={{ marginTop: "1rem" }}>
                    <div style={{ width: "700px", height: "400px", margin: "auto" }}>
                        <img className={s["puja_image_size"]} src={pujaDetailById?.pujaImage} />
                    </div>
                </div>
                <div className='puja_content'>
                    <div style={{ display: "flex", width: "100%", justifyContent: "center", padding: "1rem", color: "red" }}>
                        <h1 style={{ color: "red", fontSize: "32px" }}>About {pujaDetailById?.title}</h1>
                    </div>
                    <div className="puja_about_content">
                        {pujaDetailById?.aboutPuja}
                    </div>
                    <div style={{ display: "flex", width: "100%", alignItems: "center", padding: "1rem", color: "#fd7e14", flexDirection: "column" }}>
                        <h2>About {pujaDetailById?.title} Temple</h2>
                        <div>
                            <h2>Temple Name:-{pujaDetailById?.mandirName}</h2>
                        </div>
                    </div>
                    <div className="puja_about_content">
                        {pujaDetailById?.aboutTempalDescription}
                    </div>
                </div>
                <div className="puja_carousel">
                    <div className={s["puja_temple_carousel"]}>
                        <div style={{ paddingBlock: "1rem" }}>
                            <div className={s['puja_temple_image_size']}>
                                <Carousel rewind={true} autoPlaySpeed={1000} autoPlay={true} responsive={responsive} slidesToSlide={1}>
                                    {
                                        [0, 2, 3, 4, 5].map((data) => (
                                            <img
                                                src="https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
                                                style={{
                                                    display: 'block',
                                                    height: '400px',
                                                    margin: 'auto',
                                                    width: '100%',
                                                    borderRadius: "5px",
                                                }}
                                            />
                                        ))
                                    }
                                </Carousel>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="puja_benefit_section">
                    <div className="puja_benefit">
                        <div style={{ display: "flex", width: "100%", justifyContent: "center", padding: "1rem", color: "#fd7e14" }}>
                            <h2>Benefits of {pujaDetailById?.title}</h2>
                        </div>
                        <div className="benefit_point">
                            <ul>
                                {(pujaDetailById?.benifits)?.map((data, index) => (
                                    <p><b>{index + 1}.{data.title}:-</b>
                                        {data.description}
                                    </p>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="puja_faq_section">
                    <div className="puja_faq_heading">
                        <div style={{ display: "flex", width: "100%", justifyContent: "center", padding: "1rem", color: "#fd7e14" }}>
                            <h3>FAQs</h3>
                        </div>
                        <div style={{ marginBottom: '1rem' }}>
                            <PujaFaqSection faq={pujaDetailById?.faq} />
                        </div>
                    </div>
                </div>
                <div className="puja_subscripion_section">
                    <PujaSubscription userId={id} subscription={pujaDetailById?.packages} />
                </div>
            </div>
        </div >
    )
}

export default HOC(NavaGrahDoshNivaranPuja);
