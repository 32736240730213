import React, { useEffect, useState } from "react";

const Timer2 = ({ onEnd }) => {
    const [second, setSecond] = useState(0);

    useEffect(() => {
        const ID = setInterval(() => {
            setSecond((prev) => prev + 1);
        }, 1000);

        return () => {
            clearInterval(ID);
        };
    }, []);

    const minutes = Math.floor(second / 60);
    const sec = second % 60;

    return (
        <div>
            {`${String(minutes).padStart(2, "0")}:${String(sec).padStart(2, "0")}`}
        </div>
    );
};

export default Timer2;
