import React, { useEffect, useState } from 'react'
import "./puja.css"
import { pujaParticipate_api } from '../api/puja'
import { Button, Card } from 'react-bootstrap'
import { CardActions, CardContent, CardMedia, Typography } from '@material-ui/core'

const PujaSubscription = ({ subscription, userId }) => {

    console.log(userId, "userId in the puja participate page of the astrosetu website")

    const [pujaParticipate, setPujaParticipate] = useState([])
    const [isLoading, SetisLoading] = useState(false)

    useEffect(() => {
        pujaParticipateFunc();
    }, [])
    const pujaParticipateFunc = async () => {
        SetisLoading(true)
        let temp = {
            puja_id: "",
            puja_date: "",
            puja_amount: "",
            puja_type: "",
            user_id: userId
        }
        try {
            let res = await pujaParticipate_api(temp)
            if (res.data.status) {
                setPujaParticipate(res.data.data)
                SetisLoading(false)
            }
        } catch (error) {
            SetisLoading(false)
            console.log("status false....")

        }
    }
    console.log(subscription, "subscription----------------------------------------------------------------------------------")
    return (
        <div>

            <div className="subscription_list">
                {subscription?.map((data, index) => (
                    console.log(data, "this is the data which is coming he subscription pge of the puja listing"),
                    < div style={{ cursor: "pointer" }} className="card_set">
                        {/* <CardMedia
                            component="img"
                            alt="puja image"
                            height="140"
                            image={data?.pujaImage}
                        /> */}
                        <CardContent>
                            <Typography gutterBottom variant="h6" component="div" >
                                <div className='price_section'>
                                    <h3>₹:{data.packagePrice}</h3>
                                    <span>{(data.packageName).split("(")[0]}</span>
                                    <div>
                                        <span>{`(${(data.packageName).split("(")[1]}`}</span>
                                    </div>
                                </div>
                            </Typography>
                            <Typography variant="body2" style={{ height: "350px", textAlign: "left" }} color="text.secondary">
                                {
                                    data.packageDescription.map((item, index) => (
                                        <p>{index + 1}.<b>{(item.split(":"))[0]}</b>:{(item.split(":"))[1]}</p>
                                    ))
                                }
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: "end", width: '100%' }}>
                                <button class="button">Participate</button>
                            </div>
                        </CardActions>
                    </div>
                ))}
            </div>




        </div >
    )
}

export default PujaSubscription







// <div class="planItem">
//     <div className="subscription_list">
//         <div class="cards">
//             <div style={{ paddingLeft: '1rem' }} class="price">Rs.{data?.packagePrice}<span></span></div>
//             <div class="card__header">
//                 {/* <div class="card__icon symbol symbol--rounded"></div> */}
//                 <h2>{data?.packageType}</h2>
//             </div>
//             <div class="card__desc">{data?.packageDescription}</div>
//         </div>
//         <ul class="featureList">
//             <li><b>Package Name:</b>{data?.packageName}</li>
//             <li class="disabled">Chat support</li>
//             <li class="disabled">Mobile application</li>
//             <li class="disabled">Unlimited users</li>
//         </ul>
//         <button class="button">Participate</button>
//     </div>
// </div>
